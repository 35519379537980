/* eslint-disable no-undef,import/extensions,no-console */
// Configuration settings
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

console.log(`clutch_${clutch.env}@${clutch.version}`);

dayjs.extend(utc);
dayjs.extend(timezone);

export const ENVIRONMENT = clutch.env;
export const API_CONFIG = clutch.config.api;
export const INQUIRY_CONFIG = clutch.config.inquiry;
export const WEB_CONFIG = clutch.config.web;
export const PHOTOS_CONFIG = clutch.config.photos;
export const FILE_CONFIG = clutch.config.generalFiles;
export const VERSION = clutch.version;

export const API_URL = `${API_CONFIG.protocol}://${API_CONFIG.hostname}:${API_CONFIG.port}${API_CONFIG.path}`;

export const INQUIRY_URL = `${INQUIRY_CONFIG.protocol}://${INQUIRY_CONFIG.hostname}:${INQUIRY_CONFIG.port}${INQUIRY_CONFIG.path}`;

export const WEB_URL_BASE = `${WEB_CONFIG.protocol}://${WEB_CONFIG.hostname}`;
export const WEB_URL = `${WEB_URL_BASE}:${WEB_CONFIG.port}`;

export const PHOTO_BUCKET_URL = `${PHOTOS_CONFIG.protocol}://${PHOTOS_CONFIG.hostname}`;
export const FILE_BUCKET_URL = `${FILE_CONFIG.protocol}://${FILE_CONFIG.hostname}`;

export const WEB_DOMAIN = `${WEB_CONFIG.hostname}:${WEB_CONFIG.port}`;

export const FB_APP_ID = clutch.config.facebook.app_id;
export const FB_DEFAULT_PHOTO_URL = `${WEB_URL}/clutch-share-image.jpg`;

export const GOOGLE_MAPS_API_KEY = clutch.config.google.mapsApiKey;
export const GOOGLE_ANALYTICS_TRACKING_CODE = clutch.config.google.analyticsTrackingCode;
export const GOOGLE_TAG_MANAGER_CODE = clutch.config.google.tagManagerCode;
export const GOOGLE_TAG_MANAGER_DATA_LAYER_TRACK_ALL = clutch.config.google.tagManagerTrackAll;
export const GOOGLE_TAG_MANAGER_DATA_LAYER_TRACKED_ACTIONS = clutch.config.google.tagManagerTrackedActions;
export const GOOGLE_APP_ID = clutch.config.google.app_id;

export const TWITTER_ACCOUNT = '@clutchcanada';
export const TWITTER_DEFAULT_PHOTO_URL = `${WEB_URL}/clutch-share-image.jpg`;

export const SENTRY_LINK = clutch.config.sentry.url;

export const DATADOG_APPLICATION_ID = clutch.config.datadog.app_id;
export const DATADOG_CLIENT_TOKEN = clutch.config.datadog.client_token;
export const DATADOG_SITE = clutch.config.datadog.site;

export const LAUNCH_DARKLY_CLIENT_ID = clutch.config.launchDarkly?.clientId;

export const STRIPE_API_KEY = clutch.config.stripe.apiKey;

export const PERSONA = {
  TEMPLATE_ID: clutch.config.persona.templateId,
  ENVIRONMENT: clutch.config.persona.environment,
};

export const STATIC_PHOTO_FOLDER = 'website_static_assets';
export const PRODUCTION_PHOTO_URL = 'https://fastly.clutch.ca';
export const RUDDERSTACK_WRITE_KEY = clutch.config.rudderstack.write_key;
export const RUDDERSTACK_DATA_PLANE = clutch.config.rudderstack.data_plane;
export const BUTTER_ID = clutch.config.butter.apiKey;
export const BUTTER_REVIEW_COLLECTION = clutch.config.butter.collection;

export function getFastlyPhotoUrl({ photoId, transformation = '', original = false }) {
  return original
    ? `${clutch.config.fastly.url}/${photoId}.jpg?original=true`
    : `${clutch.config.fastly.url}/${photoId}.jpg?class=${transformation}`;
}

export function getStaticPhotoUrl(photoId) {
  const ext = photoId.split('.')[1];
  return ext === 'png' ? `${PRODUCTION_PHOTO_URL}/assets/${photoId}?class=png` : `${PRODUCTION_PHOTO_URL}/assets/${photoId}`;
}

export function getCardPhotoUrl(cardPhotoId) {
  return getFastlyPhotoUrl({ photoId: cardPhotoId, transformation: 'extra_small', formatAuto: true });
}
